<template>
	<div>
	<!-- 面包屑 -->
	<Breadcrumb></Breadcrumb>
	<el-card class="mt-2">
		<el-tabs type="border-card" v-model="tabIndex">
		  <el-tab-pane label="基础设置">
			  <div class="w-100 h-auto flex flex-column">
				<el-form ref="form" :model="form" :rules="rules" label-width="100px" size="small">
					<el-form-item label="商品类型" label-width="100px" prop="cat_id" style="width: 600px;">
						<el-select v-model="form.cat_id" placeholder="请选择商品类型" size="medium" class="w-100">
							<el-option label="无" :value="0"></el-option>
							<el-option v-for="(item,index) in goods_cat" :key="index"
								:label="item | tree" 
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="商品名称" label-width="100px" prop="name" style="width: 600px;">
					  <el-input v-model="form.name" placeholder="请输入商品名称" type="text" clearable />
					</el-form-item>
<!-- 					<el-form-item label="赠送积分" label-width="100px" prop="quedou" style="width: 600px;">
						<div class="w-100 flex align-center">
							<el-input v-model="form.piont" placeholder="请输入赠送分" type="number" clearable class="flex-1"/>
							<span class="ml-2">分</span>
						</div>
					</el-form-item> -->
					<el-form-item label="已售数量" label-width="100px" prop="sell" style="width: 600px;">
					  <el-input v-model="form.sell" placeholder="请输入已售数量" type="number" clearable />
					</el-form-item>
                    <el-form-item label="商品简介" label-width="100px" prop="brief" style="width: 600px;">
                      <el-input v-model="form.brief" placeholder="请输入商品简介" type="textarea" :rows="3" />
                    </el-form-item>
					<el-form-item  label="上线状态">
					   <el-radio-group v-model="form.status">
					   	<el-radio :label="1">是</el-radio>
					   	<el-radio :label="2">否</el-radio>
					   </el-radio-group>
					</el-form-item>
					<el-form-item  label="推荐状态">
					   <el-radio-group v-model="form.is_rec">
					   	<el-radio :label="1">是</el-radio>
					   	<el-radio :label="2">否</el-radio>
					   </el-radio-group>
					</el-form-item>
					<el-form-item  label="热门状态">
					   <el-radio-group v-model="form.is_hot">
					   	<el-radio :label="1">是</el-radio>
					   	<el-radio :label="2">否</el-radio>
					   </el-radio-group>
					</el-form-item>
					<el-form-item  label="新品状态">
					   <el-radio-group v-model="form.is_new">
					   	<el-radio :label="1">是</el-radio>
					   	<el-radio :label="2">否</el-radio>
					   </el-radio-group>
					</el-form-item>
					<el-form-item label="商品排序" label-width="100px" prop="sort" style="width: 600px;">
					  <el-input v-model="form.sort" placeholder="请输入商品排序" type="number" clearable />
					</el-form-item>
				</el-form>
			  	</div>
		  </el-tab-pane>
		  <el-tab-pane label="媒体设置">
			  <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="small">
				<el-form-item label="商品图片">
					<div class="flex flex-wrap">
						<div class="border rounded flex-all mr-3 mb-3 position-relative" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
						@click="chooseImage(index)"
						v-for="(item,index) in images" :key="index"
						v-dragging="{ item: item, list: images, group: 'imgs' }">
							<img :src="item.url" class="w-100 h-100" v-if="item.url">
							<i class="el-icon-plus text-muted" style="font-size: 30px;" v-else></i>
							<i class="el-icon-delete text-white position-absolute p-1" style="top: 0;right: 0;background-color: rgba(0,0,0,0.4);" 
							v-if="item.url"
							@click.stop="delImage(index)"></i>
						</div>
						<div class="border rounded flex-all mr-3 mb-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
						@click="chooseImage(-1)"
						v-if="images.length < 10"
						>
							<i class="el-icon-plus text-muted" style="font-size: 30px;"></i>
						</div>
					</div>
					<small class="d-block text-secondary" style="line-height: 20px;">默认第一张是主图，主图尺寸：750px*750px</small>
				</el-form-item>
			  </el-form>
		  </el-tab-pane>
		  <el-tab-pane label="商品规格">
			  <el-form ref="form" :model="form" :rules="rules" label-width="100px" size="small">
				<el-form-item  label="规格类型">
				   <el-radio-group v-model="skus_type">
				   	<el-radio :label="1">单规格</el-radio>
				   	<el-radio :label="2">多规格</el-radio>
				   </el-radio-group>
				</el-form-item>
				<template v-if="skus_type == 1">
					<el-form-item label="销售价格" label-width="100px" prop="price" style="width: 600px;">
					  <el-input v-model="form.price" placeholder="请输入销售价格" type="number" clearable />
					</el-form-item>
					<el-form-item label="市场价格" label-width="100px" prop="mktprice" style="width: 600px;">
					  <el-input v-model="form.mktprice" placeholder="请输入市场价格" type="number" clearable />
					</el-form-item>
					<el-form-item label="库存数量" label-width="100px" prop="stock" style="width: 600px;">
					  <el-input v-model="form.stock" placeholder="请输入库存数量" type="number" clearable />
					</el-form-item>
				</template>
				<template v-if="skus_type===2">
					<!-- 规格卡片 -->
					<el-form label-width="80px">
						<el-form-item label="添加规格">
						  <skuCard v-for="(item,index) in sku_card" :key="index" :item="item" :index="index" :skuCardTotal="skuCardTotal"></skuCard>
						  <el-button type="success" size="mini" @click="addSkuCard">添加规格</el-button>
						</el-form-item>
					</el-form>
					<el-form-item label="批量设置">
						<template v-if="!upAllFlag">
							<el-button type="text" 
							v-for="(item,index) in updateList" 
							:key="index"
							@click="changeUpStatus(item)">{{item.name}}</el-button>
						</template>
					  <!-- 批量设置 -->
					  <div class="d-flex align-items-center" v-else>
						 <el-input type="number" :placeholder="updatePlaceholder" size="mini" style="width: 200px;" class="mr-2" v-model="upValue"></el-input>
						 <el-button type="primary" size="mini" @click="setAllValue">设置</el-button>
						 <el-button size="mini" @click="closeUpStatus">取消</el-button>
					  </div>
					</el-form-item>
					<el-form-item label="规格设置">
						<skuTable ref="table" :sku_list="sku_list"></skuTable>
					</el-form-item>
				</template>
				<el-form-item  label="是否包邮">
				   <el-radio-group v-model="form.is_by">
					<el-radio :label="1">是</el-radio>
					<el-radio :label="2">否</el-radio>
				   </el-radio-group>
				</el-form-item>
				<el-form-item label="运费" label-width="100px" prop="cost_freight" style="width: 600px;" v-if="form.is_by == 2">
					<div class="w-100 flex align-center">
						<el-input v-model="form.cost_freight" placeholder="请输入运费" type="number" clearable class="flex-1"/>
						<span class="ml-2">元</span>
					</div>
				</el-form-item>
			  </el-form>
		  </el-tab-pane>
		  <el-tab-pane label="商品详情">
			  <el-form ref="form" :model="form" :rules="rules" size="small">
				<el-form-item label="">
                   <tinymce ref="editor" :key="tinymceFlag" v-model="form.content" />
				</el-form-item>
			  </el-form>
		  </el-tab-pane>
		  <div class="w-100 flex-all mt-2">
		    <el-button size="medium" @click="goBack">取 消</el-button>
		    <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
		  </div>
		</el-tabs>

	</el-card>
	</div>
</template>

<script>
import { mapState,mapMutations,mapGetters } from "vuex"
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
import tinymce from "@/components/common/tinymce.vue"
import skuCard from "@/components/goods/sku_card.vue"
import skuTable from '@/components/goods/sku_table.vue'
export default {
	inject:['app'],
	mixins:[common],
	components: {
		Breadcrumb,
		tinymce,
		skuCard,
		skuTable
	},
	data() {
		return {
			preUrl: 'goods',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
			},
			form:{
				cat_id:'',
				name:'',
				is_by:1,
				brief:'',
				status:1,
				is_rec:2,
				is_hot:2,
				is_new:2,
				sort:100,
				price:'',
				mktprice:'',
				stock:100,
				content:'',
				piont:'',
				piont_ratio:'',
				sell:'',
			},
			rules:{
				cat_id: [
				  { required: true, message: '请选择商品类型', trigger: ['blur','change'] }
				],
				name: [
				  { required: true, message: '请输入商品名称', trigger: ['blur','change'] }
				],
			},
			tabIndex:0,
			goods_cat:[],
			images:[],
			skus_type:1,
			sku_list:[],
			sku_labels:[],
			//批量修改
			upAllFlag:false,
			updatePlaceholder:'',
			upValue:'',
			updateList:[
				{
					name:'销售价',
					key:'price'
				},
				{
					name:'市场价',
					key:'mktprice'
				},
				{
					name:'库存',
					key:'stock'
				}
			],
			tinymceFlag:0,
		}
	},
	mounted() {
		if(this.$route.query.info){
			 this.form = this.$route.query.info
			 this.images = this.$route.query.info.images
			 this.skus_type = this.$route.query.info.skus_type
			 this.vModel('is_default',this.form.is_default)
			 this.$store.state.goods.sku_card = [];
			 this.skuCard(this.form.sku_card)
			 this.$nextTick(()=>{
				this.sku_list = this.form.spes_desc || []
			 })
		}else{
			this.vModel('is_default',1)
			this.$store.state.goods.sku_card = [];
		}
		this.getGoodsCat()
	},
	computed:{
		...mapState({
			sku_card:state=>state.goods.sku_card,//规格卡片
		}),
		...mapGetters([
			'goodsData'
		]),
		skuCardTotal(){
			return this.sku_card.length
		},
	},
	filters: {
		tree(item) {
			if (item.level == 0) {
				return item.name
			}
			let str = ''
			for (let i = 0; i < item.level; i++) {
				str += i == 0 ? '|--' : '--'
			}
			return str + ' ' +item.name;
		}
	},
	methods:{
		...mapMutations(['eState','addSkuCard','editGoodsAttrs','skuCard']),
		vModel(key,val){
			this.eState({key,val})
		},
		getGoodsCat(){
			this.axios.get('/manage/goods_cat/list').then(res=>{
				if(res.status){
					this.goods_cat = res.data
				}
			})
		},
		reset() {
		  this.form = {
			 cat_id:'',
			 name:'',
			 is_by:1,
			 brief:'',
			 status:1,
			 is_rec:2,
			 is_hot:2,
			 is_new:2,
			 sort:100,
			 price:'',
			 mktprice:'',
			 stock:100,
			 content:'',
			 piont:'',
			 piont_ratio:'',
			 sell:'',
		  }
		  this.resetForm('form')
		},
		chooseImage(index){
			const MAX = 10
			let conut = MAX - this.images.length
			this.app.chooseImage(res=>{
				let list = []
				if(index === -1){
					list = [...this.images,...res]
				}else{
					list = [...this.images]
					list[index] = res[0]
				}
				this.images = list
			}, index === -1 ? conut : 1)
		},
		delImage(index){
			this.$confirm('删除该文件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let list = [...this.images]
				list.splice(index,1)
				this.images = list
			}).catch(() => {
				
			});
		},
		changeUpStatus(item){
			//显示批量修改表单
			this.upAllFlag = item.key
			this.updatePlaceholder = item.name
			this.updatePlaceholder = item.name
		},
		closeUpStatus(){//取消批量修改状态
			this.upValue = ''
			this.upAllFlag = false
		},
		setAllValue(){//批量设置
			this.$refs.table.list.forEach(v=>{
				v[this.upAllFlag] = this.upValue
			})
			this.closeUpStatus()
		},
		handleSubmit() {
		  this.$refs.form.validate(valid => {
		    if (valid) {
			  this.form.images = this.images
			  this.form.skus_type = this.skus_type
			  this.form.sku_card = this.goodsData
			  this.form.skus = this.sku_card
			  this.form.is_default = this.$store.state.goods.is_default
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('添加成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
				if(this.sku_list.length == 0){
					this.form.sku_card = this.goodsData
				}else{
					this.form.sku_card = this.sku_list
				}
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		goBack(){
			this.$router.go(-1);
		},
	},
}
</script>

<style scoped>
/deep/ .el-tabs--border-card {
	/* border:none; */
	box-shadow:none;
}
</style>
